import React from "react";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ShowMoreList from "../../../components/ShowMoreList";
import Tag from "../../../components/Tag";
import {
  EXPORT_TYPE_CSV,
  EXPORT_TYPE_PDF,
  EXPORT_TYPE_XLS,
  EXPORT_TYPE_ZIP
} from "../../../components/SmartTable/ExportDropdown/ExportOverlay";
import { downloadTableRow } from "../TrgPlanning/methods";
import { closeModalAction, openModalAction } from "../../../components/Modal/reducer";
import { MODAL_EXPORT_LOADING } from "../../../components/SmartTable/ExportDropdown/LoadingModal";
import { PAGE_VIEW_USER } from "../../Core/routes";
import Tooltip from "../../../components/Tooltip";

const EmployeeCell = ({ row }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const exportFile = (exportType) => (e) => {
    e.preventDefault();

    let route;

    if (exportType === EXPORT_TYPE_PDF) {
      route = `trg/qualifications-export/${row.user_detail.user_id}?filename=_TRG+user+qualifications&type=pdf`;
    }

    if (exportType === EXPORT_TYPE_XLS) {
      route = `trg/qualifications-export/${row.user_detail.user_id}?filename=_TRG+user+qualifications&type=xls`;
    }

    if (exportType === EXPORT_TYPE_CSV) {
      route = `trg/qualifications-export/${row.user_detail.user_id}?filename=_TRG+user+qualifications&type=csv`;
    }

    if (exportType === EXPORT_TYPE_ZIP) {
      route = `trg/qualifications-export/${row.user_detail.user_id}?filename=_TRG+user+qualifications&type=zip`;
    }

    downloadTableRow({
      route,
      onStart: () => dispatch(openModalAction({ name: MODAL_EXPORT_LOADING })),
      onSuccess: () => dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING })),
      onFailed: () => dispatch(closeModalAction({ name: MODAL_EXPORT_LOADING }))
    });
  };

  return (
    <div className="qualification-employee">
      <div className="font-weight-400 text-overflow-multiple">
        {row.name} {row.user_detail?.staff_number && `(${row.user_detail.staff_number})`}
        <Tooltip title={t("trg.qualifications.view_history")}>
          <NavLink
            to={{
              pathname: `${PAGE_VIEW_USER}/${row.user_detail.user_id}`
            }}
            className="ml-10 text-primary"
          >
            <i className="ti-eye" />
          </NavLink>
        </Tooltip>
      </div>
      <div className="text-muted font-weight-400 mb-10 text-overflow-multiple">{row.user_detail?.job_title || "-"}</div>
      <ShowMoreList
        data={row.functions.map((item) => item.name)}
        className="mb-10 functions"
        showMoreTrans="trg.reporting.show_all_functions"
        showLessTrans="trg.reporting.show_less_functions"
      />
      {row.contractStartedFormatted && <Tag className="tag-primary">{row.contractStartedFormatted}</Tag>}
      {row.contractEndedFormatted && (
        <>
          <span>/</span> <Tag className="tag-secondary">{row.contractEndedFormatted}</Tag>
        </>
      )}
      <div className="export-qualification">
        <a href="/" className="text-danger" onClick={exportFile(EXPORT_TYPE_PDF)}>
          <FilePdfOutlined />
        </a>
        <a href="/" onClick={exportFile(EXPORT_TYPE_XLS)}>
          <FileExcelOutlined />
        </a>
        <a href="/" onClick={exportFile(EXPORT_TYPE_CSV)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="svg-inline--fa fa-file-csv fa-w-12"
            data-icon="file-csv"
            data-prefix="fas"
            viewBox="0 0 384 512"
          >
            <path
              fill="#888A85"
              d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm-96 144c0 4.42-3.58 8-8 8h-8c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h8c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-8c-26.51 0-48-21.49-48-48v-32c0-26.51 21.49-48 48-48h8c4.42 0 8 3.58 8 8v16zm44.27 104H160c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h12.27c5.95 0 10.41-3.5 10.41-6.62 0-1.3-.75-2.66-2.12-3.84l-21.89-18.77c-8.47-7.22-13.33-17.48-13.33-28.14 0-21.3 19.02-38.62 42.41-38.62H200c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8h-12.27c-5.95 0-10.41 3.5-10.41 6.62 0 1.3.75 2.66 2.12 3.84l21.89 18.77c8.47 7.22 13.33 17.48 13.33 28.14.01 21.29-19 38.62-42.39 38.62zM256 264v20.8c0 20.27 5.7 40.17 16 56.88 10.3-16.7 16-36.61 16-56.88V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v20.8c0 35.48-12.88 68.89-36.28 94.09-3.02 3.25-7.27 5.11-11.72 5.11s-8.7-1.86-11.72-5.11c-23.4-25.2-36.28-58.61-36.28-94.09V264c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8zm121-159L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z"
            ></path>
          </svg>
        </a>
        <a href="/" className="black" onClick={exportFile(EXPORT_TYPE_ZIP)}>
          <i className="ti-zip" />
        </a>
      </div>
    </div>
  );
};

export default EmployeeCell;
